.DatePicker {
  position: relative;
  /* height: 100%; */
  width: 100%;
  display: flex;
  flex-direction: column;
  user-select: none;
}

.DatePicker-cont-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.DatePicker-cont {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.DatePicker-date-pick {
  height: 25px;
  width: 145px;
  background-color: rgb(241, 241, 241);
  border-radius: 4px;
  cursor: pointer;
  color: rgb(104, 104, 104);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.DatePicker-title {
  font-size: 12px;
  font-weight: 600;
  color: rgb(80, 80, 80);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.DatePicker-label {
  font-size: 10px;
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.DatePicker-date {
  width: 0;
  height: 0;
  visibility: hidden;
}

.DatePicker-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}