.HomeOwner {
  height: 90%;
  width: 90%;
  padding: 5%;
  background-color: white;
  user-select: none;
}

.HomeOwner-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
}

.HomeOwner-grid-card {
  padding: 5%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}

.HomeOwner-main-a {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.text-h1 {
  font-size: 14px;
  color: azure;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.HomeOwner-main-b {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.text-h2 {
  font-size: 22px;
  font-weight: 600;
  color: azure;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}