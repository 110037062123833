.DateTimeSlot {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  user-select: none;
}

.DateTimeSlot-cont-col {
  height: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.DateTimeSlot-cont {
  height: 20%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.DateTimeSlot-listview {
  height: 70%;
}

.DateTimeSlot-list-adapter {
  padding-left: 10px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.DateTimeSlot-list-adapter-index {
  width: 3%;
  font-size: 8px;
  font-weight: 600;
  color: rgb(80, 80, 80);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.DateTimeSlot-list-adapter-card {
  width: 97%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.DateTimeSlot-list-adapter-title {
  width: 100%;
  font-size: 12px;
  font-weight: 600;
  color: rgb(80, 80, 80);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.DateTimeSlot-date-pick {
  height: 25px;
  width: 145px;
  background-color: rgb(241, 241, 241);
  border-radius: 4px;
  cursor: pointer;
  color: rgb(104, 104, 104);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.DateTimeSlot-label {
  font-size: 10px;
  font-weight: 500;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.DateTimeSlot-date {
  width: 0;
  height: 0;
  visibility: hidden;
}