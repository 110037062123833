.ToogleButton-switch {
  cursor: pointer;
  position: relative;
  width: var(--width);
  height: calc(var(--width) / 1.8);
}

.ToogleButton-switch-frame {
  display: flex;
  align-items: center;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  height: 100%;
  width: 100%;
  border-radius: calc((var(--width) / 1.8) / 2);
  border: none;
}

.ToogleButton-switch-frame.active {
  background-color: #2196f3;
}

.ToogleButton-switch-frame.inactive {
  background-color: #ff5f6f;
}

.ToogleButton-switch-frame.disabled {
  background-color: #cccccc;
}

.ToogleButton-switch-roll {
  position: absolute;
  height: 60%;
  width: 36%;
  border-radius: 50%;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  background-color: white;
  border: none;
}

.ToogleButton-switch-roll.active {
  -webkit-transition: 0.4s;
  transition: 0.4s;
  transform: translateX(152%);
  margin-right: 10%;
}

.ToogleButton-switch-roll.inactive {
  left: 0;
  margin-left: 10%;
}
