.Timekeeping {
  height: 100%;
  width: 100%;
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  user-select: none;
}

.Timekeeping-left-panel {
  width: 30%;
  height: 100%;
  border-right: 1px solid rgb(214, 223, 231);
}

.Timekeeping-count-label {
  font-size: 11px;
  color: rgb(80, 80, 80);
  font-weight: 600;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.Timekeeping-left-list {
  height: 91%;
}

.Timekeeping-list-adapter {
  padding-left: 10px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.Timekeeping-list-adapter-index {
  width: 5%;
  font-size: 8px;
  font-weight: 600;
  color: rgb(80, 80, 80);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.Timekeeping-list-adapter-card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.Timekeeping-list-adapter-perc {
  font-size: 11px;
  font-weight: 600;
  color: rgb(80, 80, 80);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.Timekeeping-list-adapter-update {
  font-size: 10px;
  color: rgb(80, 80, 80);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.Timekeeping-right-panel {
  height: 100%;
  width: 100%;
  position: relative;
}

.Timekeeping-right-content {
  height: 95%;
  width: 90%;
  padding: 5%;
  display: flex;
  flex-direction: column;
  justify-content: var(--justifyContent);
  overflow: auto;
  scrollbar-width: none;
  scroll-behavior: smooth;
  overscroll-behavior: none;
}

.Timekeeping-right-content::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.Timekeeping-right-content {
  scrollbar-width: none;
}

.Timekeeping-right-content::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.Timekeeping-right-content::-webkit-scrollbar-track {
  background-color: transparent;
}

.Timekeeping-right-empty-content {
  text-align: center;
  font-size: 12px;
  color: rgb(80, 80, 80);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.Timekeeping-detail-perc {
  font-size: 12px;
  font-weight: 600;
  color: rgb(80, 80, 80);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.Timekeeping-detail-update {
  font-size: 12px;
  color: rgb(80, 80, 80);
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.Timekeeping-list-adapter-index-load {
  width: 5%;
  font-size: 8px;
  color: rgba(237, 237, 237, 0.559);
  background-color: rgba(237, 237, 237, 0.559);
}

.Timekeeping-list-adapter-perc-load {
  color: rgba(237, 237, 237, 0.827);
  background-color: rgba(237, 237, 237, 0.827);
  font-size: 12px;
  font-weight: 600;
}

.Timekeeping-list-adapter-update-load {
  font-size: 10px;
  color: rgba(237, 237, 237, 0.559);
  background-color: rgba(237, 237, 237, 0.559);
}

.Timekeeping-container {
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(214, 223, 231);
  padding: 10px;
  border-radius: 4px;
}

.Timekeeping-container-row {
  display: flex;
  flex-direction: row;
}

.Timekeeping-list-adapter-break {
  width: 80%;
}