.GoogleMapMain {
  height: var(--height);
  width: 100%;
  background: none;
  overflow: hidden;
  position: relative;
  user-select: none;
}

.containerStyle {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.addrest-search {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: "32px";
  z-index: 1;
  background-color: transparent;
  padding: 5px;
  box-sizing: border-box;
}
